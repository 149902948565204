<template>
  <div class="oral-snag-page">
    <van-nav-bar left-arrow fixed left-text="返回" @click-left="$router.go(-1)">
      <template #right>
        <i class="iconfont icon-a-Medicaldiagnosis"></i>
        <p class="exit" @click="diagnosis">医疗诊断</p>
      </template>
    </van-nav-bar>
    <div class="container">
      <div class="tip" v-if="tipShow">
        <span>如有医疗诊断，请点击右上角“医疗诊断”填写医疗诊断内容</span>
        <van-icon name="cross" @click="close" />
      </div>
      <div class="weidu-container">
        <p class="explain">口腔障碍评估包括以下维度，需按照{{ listTxt }}的顺序评估，请点击【开始评估】按钮进行评估</p>
        <div class="weidu-list">
          <ul>
            <li v-for="(item,index) in list" :key="item.main_id">
              <div class="background" :class="{ completed: item.is_finish * 1 === 1 }"></div>
              <div class="content">
                <i class="iconfont" :class="iconList[index % 6]"></i>
                <p>{{ item.main_name }}</p>
                <div
                  class="tag"
                  :class="{ show: item.is_finish * 1 > 0 }"
                >{{ ['','已评估','评估中'][item.is_finish] }}</div>
              </div>
            </li>
            <li v-for="item in 10" :key="item" class="empty"></li>
          </ul>
        </div>
        <div class="button-group">
          <van-button color="#FFAA20" @click="assess">{{['开始评估','继续评估','继续评估'][btnStatus]}}</van-button>
          <van-button color="#F14947" :disabled="btnStatus !== 2" @click="finish">完成评估</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oralSnag",
  data() {
    return {
      list: [],
      tipShow: true,
      iconList: [
        'icon-a-Oralperception',
        'icon-feed',
        'icon-a-thelowerjaw',
        'icon-breath',
        'icon-lip',
        'icon-tongue',
      ]
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
      }
      let res = await this.$http.get('pad/oral-snag/oral-main', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.list = res.data instanceof Array ? res.data : []
    },
    diagnosis() {
      this.$router.push({
        name: "diagnosis",
        query: {
          ...this.$route.query
        },
      });
    },
    close() {
      this.tipShow = false
    },
    async assess() {
      let flag = this.list.some(e => e.is_finish * 1 !== 1)
      if (!flag) {
        return this.$vantnotify('已评估完成，无可评估维度');
      }
      let info = this.list.find(e => e.is_finish * 1 !== 1)
      if (info.is_finish * 1 === 2) {
        let params = {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          lesson_id: info.lesson_id,
          manor_id: info.manor_id,
          main_id: info.main_id,
        }
        let res = await this.$http.get('pad/oral-snag/main-enter', { params })
        if (res.code * 1 !== 1) {
          return this.$vantnotify(res.msg);
        }
        if (!res.data.info.id) {
          return this.$vantnotify('获取不到当前维度评估题信息');
        }
        this.$router.push({
          name: "oralTopic",
          query: {
            ...this.$route.query,
            lesson_id: res.data.info.lesson_id,
            manor_id: res.data.info.manor_id,
            main_id: res.data.info.main_id,
            stage_id: res.data.info.stage_id,
            id: res.data.info.id,
            mailtrec_id: res.data.mailtrec_id
          },
        });
      } else {
        this.$router.push({
          name: "criterion",
          query: {
            ...this.$route.query,
            lesson_id: info.lesson_id,
            manor_id: info.manor_id,
            main_id: info.main_id,
            main_name: info.main_name
          },
        });
      }
    },
    async finish() {
      let flag = this.list.some(e => e.is_finish * 1 === 1 && e.is_curr * 1 === 1)
      if (flag) {
        let params = {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
        }
        const res = await this.$http.post('pad/oral-snag/oral-ass', params);
        if (res.code * 1 !== 1) {
          return this.$vantnotify(res.msg);
        }
        this.$toast.success({
          duration: 3000,
          forbidClick: true,
          message: "评估完成",
          onClose: () => {
            this.$router.go(-1)
          },
        });
      }
    }
  },
  computed: {
    btnStatus() {
      let status = 0
      if (this.list.some(e => e.is_finish * 1 > 0 && e.is_curr === 1)) {
        status = 1
      }
      if (this.list.some(e => e.is_finish * 1 === 1 && e.is_curr === 1)) {
        status = 2
      }
      return status;
    },
    listTxt() {
      let txt = ''
      if (this.list.length > 0) {
        txt = this.list.map(e => e.main_name).join('、')
      }
      return txt
    },
  },
};
</script>

<style lang="less" scoped>
.oral-snag-page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  height: 100vh;
  padding-top: 0.46rem;
  box-sizing: border-box;
  button {
    border: none;
  }
  .van-nav-bar {
    background: rgba(255, 255, 255, 0.3);
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__content {
      height: 0.46rem;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    /deep/ .van-nav-bar__right {
      font-size: 0.16rem;
      color: #fe6765;
      .iconfont {
        font-size: 0.2rem;
        color: #fe6765;
        margin-right: 0.05rem;
      }
    }
  }
  .container {
    padding: 0.15rem 0.2rem;
    height: calc(100vh - 0.46rem);
    overflow-y: scroll;
    box-sizing: border-box;
    .tip {
      height: 0.48rem;
      background: #ffecec;
      border-radius: 0.06rem;
      border: 1px solid #ffc4c4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.2rem;
      font-size: 0.16rem;
      color: #f14947;
      .van-icon {
        font-size: 0.12rem;
        color: #630200;
        font-weight: bold;
      }
    }
    .weidu-container {
      background: #ffffff;
      border-radius: 0.06rem;
      padding: 0.15rem 0.2rem 0.5rem;
      margin-top: 0.16rem;
      .explain {
        font-size: 0.18rem;
        color: #333;
        line-height: 2;
      }
      .weidu-list {
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        li {
          width: 1.44rem;
          height: 0.9rem;
          border-radius: 0.14rem;
          margin-bottom: 0.2rem;
          box-sizing: border-box;
          color: #2b1a00;
          position: relative;
          overflow: hidden;
          &.empty {
            height: 0;
            border: none;
            margin-bottom: 0;
            padding: 0;
          }
          .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0.14rem;
            background: #fffcf6;
            border: 1px solid #ffebb7;
            box-sizing: border-box;
            &.completed {
              background: #ffba00;
              border: 1px solid #ffba00;
            }
          }
          .content {
            position: absolute;
            padding: 0.12rem 0.18rem;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
          .iconfont {
            font-size: 0.36rem;
          }
          p {
            font-size: 0.18rem;
            margin: 0;
          }
          .tag {
            width: 0.7rem;
            height: 0.7rem;
            background: #f14947;
            font-size: 0.1rem;
            color: #fff;
            text-align: center;
            line-height: 1.2rem;
            display: none;
            position: absolute;
            top: -0.35rem;
            right: -0.35rem;
            transform: rotate(45deg);
            &.show {
              display: block;
            }
          }
        }
      }
      .button-group {
        margin-top: 0.5rem;
        text-align: center;
        button {
          width: 1.2rem;
          height: 0.38rem;
          font-size: 0.16rem;
          border-radius: 0.06rem;
          margin: 0 0.1rem;
        }
      }
    }
  }
}
</style>